import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Converter from './components/Converter';
import Profile from './components/Profile';

const PrivateRoute = ({ children }) => {
    const username = localStorage.getItem('username');
    return username ? children : <Navigate to="/login" />;
};

const App = () => {
    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/converter"
                        element={
                            <PrivateRoute>
                                <Converter />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <PrivateRoute>
                                <Profile />
                            </PrivateRoute>
                        }
                    />
                    <Route path="/" element={<Navigate to="/login" />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
