import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Header from './Header';
import Icon from '@mdi/react';
import { mdiDownload, mdiSend, mdiProgressUpload, mdiAlertCircle, mdiCheckCircle } from '@mdi/js';
import '../styles/Converter.css';

function Converter() {
    const [file, setFile] = useState(null);
    const [processedFilename, setProcessedFilename] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // error, success-elaborazione, success-api
    const [inputNumber, setInputNumber] = useState(0);
    const [isNumberInvalid, setIsNumberInvalid] = useState(false);
    const userFirstName = localStorage.getItem('first_name');
    const userLastName = localStorage.getItem('last_name');
    const username = localStorage.getItem('username');

    useEffect(() => {
        fetchInputNumber();
    }, []);

    const fetchInputNumber = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/number`);
            setInputNumber(response.data);
        } catch (error) {
            console.error(error.message);
        }
    };

    const onDrop = useCallback(async (acceptedFiles) => {
        setFile(acceptedFiles[0]);
        setProcessedFilename("");
        setMessage('');
        await fetchInputNumber();
        setInputNumber(prevInputNumber => prevInputNumber + 1);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleFileUploadAndProcess = async () => {
        if (!file) {
            setMessage('Nessun file selezionato');
            setMessageType('error');
            return;
        }

        if (isNumberInvalid) {
            setMessage('Numero di fattura non valido');
            setMessageType('error');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('invoice_number', inputNumber);
        formData.append('username', username);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/file/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setMessage(response.data.message);
            setMessageType('success-elaborazione');
            setProcessedFilename(response.data.processedFilename);
        } catch (error) {
            console.error(error);
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Errore durante upload e conversione del file';
            setMessage(errorMessage);
            setMessageType('error');
            setProcessedFilename("");
        }
    };

    const handleFileDownload = async () => {
        if (!processedFilename) {
            setMessage('Nessun file processato pronto per il download');
            setMessageType('error');
            return;
        }

        const url = `${process.env.REACT_APP_API_URL}/file/download/${processedFilename}`;
        try {
            const response = await axios.get(url, {
                responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: 'application/xml' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', processedFilename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error(error);
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Errore durante il download del file';
            setMessage(errorMessage);
            setMessageType('error');
        }
    };

    const handleFileSend = async () => {
        if (!processedFilename) {
            setMessage('Nessun file processato pronto per essere inviato');
            setMessageType('error');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/file/send/${processedFilename}`);
            setMessage(response.data.message);
            setMessageType('success-api');
        } catch (error) {
            console.error(error);
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Errore durante l\'invio del file';
            setMessage(errorMessage);
            setMessageType('error');
        }
    };

    const handleNumberChange = (e) => {
        const value = e.target.value;
        setInputNumber(value);
        setIsNumberInvalid(!e.target.checkValidity());
    };

    return (
        <div>
            <Header />
            <div className="converter-container">
                <h2>Benvenuto, {userFirstName} {userLastName}</h2>
                <div className="dropzone-container">
                    <div {...getRootProps({ className: `dropzone ${file ? 'file-loaded' : ''}` })}>
                        <input {...getInputProps()} />
                        {file ? <p>{file.name}</p> : <p>Per iniziare, trascina qui un file XML o clicca per selezionarne uno</p>}
                    </div>
                    <button
                        className="dropzone-button button-converter"
                        onClick={handleFileUploadAndProcess}
                        disabled={!file || processedFilename}
                    >
                        <Icon path={mdiProgressUpload} size={3} color="white" className="icon-spacing" />
                        <span>Elabora file</span>
                    </button>
                    <div className="number-input-container">
                        <label htmlFor="inputNumber" className="number-label">N. Fattura:</label>
                        <input
                            type="number"
                            value={inputNumber}
                            onChange={handleNumberChange}
                            id="inputNumber"
                            min="1"
                            max="99999999999999999999"
                            className={`input-number ${isNumberInvalid ? 'input-error' : ''}`}
                            placeholder="---"
                            disabled={!file || processedFilename}
                        />
                    </div>
                </div>
                <div className='message-container'>
                    {message && (
                        <div className={`message ${messageType}`} style={{ whiteSpace: 'pre-line' }}>
                            <span>{message}</span>
                            <Icon
                                path={messageType === 'error' ? mdiAlertCircle : mdiCheckCircle}
                                size={2}
                                color={messageType === 'error' ? 'red' : (messageType === 'success-elaborazione' ? '#00a938' : '#7695ff')}
                                className="icon-message"
                            />
                        </div>
                    )}
                </div>
                <div className="action-buttons">
                    <button
                        className="button-converter larger-button"
                        onClick={handleFileSend}
                        disabled={!processedFilename}
                    >
                        <Icon path={mdiSend} size={2} color="white" className="icon-spacing" />
                        <span>Invia a fattureincloud</span>
                    </button>
                    <button
                        className="button-converter smaller-button"
                        onClick={handleFileDownload}
                        disabled={!processedFilename}
                    >
                        <Icon path={mdiDownload} size={1} color="white" className="icon-spacing" />
                        <span>Scarica XML AdE</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Converter;
