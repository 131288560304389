import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiAccountCircle, mdiLogout } from '@mdi/js';
import '../styles/Header.css';

function Header({ showProfileLink = true }) {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('username');
        localStorage.removeItem('first_name');
        localStorage.removeItem('last_name');
        navigate('/login');
    };

    return (
        <div className="header">
            <Link to="/converter">
                <img src="/laus_logo_full.png" alt="Client Logo" className="header-logo" />
            </Link>
            <div className="header-actions">
                {showProfileLink && (
                    <Link to="/profile">
                        <Icon path={mdiAccountCircle} size={1} color="white" />
                    </Link>
                )}
                <button onClick={handleLogout} className="header-logout">
                    <Icon path={mdiLogout} size={1} color="white" />
                </button>
            </div>
        </div>
    );
}

export default Header;
