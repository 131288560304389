import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Icon from '@mdi/react';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import '../styles/Profile.css';

function Profile() {
    const [user, setUser] = useState({
        username: localStorage.getItem('username'),
        password: '',
        first_name: localStorage.getItem('first_name'),
        last_name: localStorage.getItem('last_name')
    });
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/profile`, user);
            setMessage(response.data.message);

            if (localStorage.getItem('username') === user.username) {
                localStorage.setItem('first_name', user.first_name);
                localStorage.setItem('last_name', user.last_name);
            }

            navigate('/converter');
        } catch (error) {
            if (error.response && error.response.data) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Impossibile inserire/aggiornare l\'utente');
            }
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            <Header showProfileLink={false} />
            <div className="profile-container">
                <form onSubmit={handleSubmit} className="profile-form">
                    <h2>Gestione utente</h2>
                    <label htmlFor="first_name">Nome</label>
                    <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        placeholder="Nome"
                        value={user.first_name}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="last_name">Cognome</label>
                    <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        placeholder="Cognome"
                        value={user.last_name}
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="Username"
                        value={user.username}
                        onChange={handleChange}
                        disabled={localStorage.getItem('username') !== 'admin' && localStorage.getItem('username') !== 'admin-laus'}
                        required
                    />
                    <label htmlFor="password">Password</label>
                    <div className="password-container">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            id="password"
                            placeholder="Password"
                            onChange={handleChange}
                            required
                        />
                        <button type="button" onClick={toggleShowPassword} className="toggle-password-button">
                            <Icon path={showPassword ? mdiEyeOff : mdiEye} size={1} />
                        </button>
                    </div>
                    <button type="submit" className="submit-button">SALVA</button>
                    <div className="error-message" style={{ whiteSpace: 'pre-line' }}>
                        {message && <p>{message}</p>}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Profile;
