import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import Icon from '@mdi/react';
import '../styles/Login.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, {
                username,
                password
            });
            localStorage.setItem('username', response.data.username);
            localStorage.setItem('first_name', response.data.first_name);
            localStorage.setItem('last_name', response.data.last_name);
            setMessage(response.data.message);
            navigate('/converter');
        } catch (error) {
            if (error.response && error.response.data) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Impossibile effettuare il login. Controllare le credenziali inserite e riprovare');
            }
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="login-container">
            <div className="login-logo-container">
                <img src="/laus_logo.png" alt="Client Logo" className="login-logo" />
            </div>
            <div className="login-form-container">
                <form onSubmit={handleSubmit} className="login-form">
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <label htmlFor="password">Password</label>
                    <div className="password-container">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button type="button" onClick={toggleShowPassword} className="toggle-password-button">
                            <Icon path={showPassword ? mdiEyeOff : mdiEye} size={1} />
                        </button>
                    </div>
                    <button type="submit" className="submit-button">LOGIN</button>
                </form>
            </div>
            <div className="error-message" style={{ whiteSpace: 'pre-line' }}>
                {message && <p>{message}</p>}
            </div>
            <div className="login-footer-container">
                Powered by <img src="/olicom_logo.png" alt="Olicom Logo" className="olicom-logo" />
            </div>
        </div>
    );
}

export default Login;
